
import BaseLayout from '../BaseLayout.vue'
import { vedaService } from '../../services/vedaService'
import {
  IonLabel,
  IonList,
  // IonListHeader, IonToggle,
  IonCard,
  // IonButton,
  IonIcon // , IonTabs, IonTabBar, IonTabButton
} from '@ionic/vue'
import { refreshOutline } from 'ionicons/icons'
// const { parse } = require('json2csv')
// import parse from 'json2csv'
import DashboardChart from './DashboardChart.vue'
import DashboardPowerChart from './DashboardPowerChart.vue'
import MeterEnergyFlow from './MeterEnergyFlow.vue'
// import Online from './Online.vue'
// import Terminal from './Terminal.vue'
import { defineComponent } from 'vue'
import moment from 'moment'

const vedaDetails = vedaService.getVedaDetails()
const meterIds = vedaDetails.meters.map(meter => meter.meterId)
const selectedMeterId = meterIds[0]
// console.log(meterIds)
export default defineComponent({
  name: "Dashboard",
  components: {
    BaseLayout,
    IonLabel,
    IonList,
    IonCard,
    IonIcon,
    DashboardChart,
    DashboardPowerChart,
    MeterEnergyFlow
  },
  data() {
    return {
      meterIds,
      selectedMeterId,
      meterData: {} as any,
      power: {},
      energyFlowUpdated: null as any,
      localDates: [],
      returnData: 'aligned15minRead',
      energyData: [],
      energyMeterFlow: undefined,
      loading: true,
      circuits: [],
      selectedTab: 'online',
      vifData: undefined,
      debug: false,
      displayOnDebug: 'ion-hide',
      refreshOutline,
      intervalId: 0
    }
  },
  created(this: any) {
    this.moment = moment
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
  async mounted(this: any) {
    const d = new Date()
    this.localDates.push(d.toISOString().substr(0, 10).replaceAll('-',''))
    this.getMeterEnergyFlow()
    this.intervalId = setInterval(() => {
      console.log('dashboard:mounted:getEnergyMeterFlow')
      this.getMeterEnergyFlow()
    }, 15000) // Refresh every 15 seconds
    this.getVedaEnergy()

    const meterIds = vedaService.getMeterIds()
    if (this.debug) console.log('meterIds: ', meterIds)
    if (Array.isArray(meterIds) && meterIds.length > 0) {
      this.selectedMeterId = meterIds[0]
      if (this.debug) console.log('selectedMeterId: ', this.selectedMeterId)
    }
  },
  computed: {
    netMeterRead(this: any) {
      return this.circuits.reduce(function (sum: any, curr: any) {
        return sum + curr.power;
      }, 0)
    }
  },
  methods: {
    onChangeMeter(e) {
      console.log(e)
      console.log(this.selectedMeterId)
    },
    async getVedaEnergy(this: any) {
      this.loading = true
      const res = await vedaService.getVedaEnergy(this.meterIds, this.localDates, this.returnData)
      this.energyData = res.data.energyData
      if (this.debug) console.log('dashboard:getVedaEnergy:energyData', this.energyData)
      this.loading = false
    },
    metersSegmentChanged(e, meter) {
      console.log(e)
      this.meterData[meter].current = e
    },
    changeCircuitStatus(this: any) {
      console.log("HELLO WORLD!")
    },
    changeTabs(this: any, value) {
      this.selectedTab = value
    },
    async getMeterEnergyFlow() {
      this.debug = vedaService.getDebug() // show / hide debug info
      this.displayOnDebug = this.debug ? '' : 'ion-hide'
      if (this.debug) console.log('this.displayOnDebug: ', this.displayOnDebug)
      const response = await vedaService.getMeterEnergyFlow()
      if (this.debug) console.log('getMeterEnergyFlow:response: ', response)
      this.power = response.data.energyData.data.power
      this.power['subLoad1'] = this.power['subLoad1'] || 0
      this.energyMeterFlow = response.data.energyData
      this.energyFlowUpdated = new Date()
      response.data.energyData.data['lastUpdated'] = this.energyFlowUpdated
      if (this.debug) console.log(this.power)
    },
    filteredLoad(loadNameStartsWith) {
      // "power":{ "gridLoad":0.408,"subLoad1":-1,"subLoad2":0.085,"mainLoad":1.324 }
      // const filteredLoad = this.power.filter(p => p.circuit.startsWith(loadNameStartsWith))
      const filteredKeys = Object.keys(this.power).filter(key => key.startsWith(loadNameStartsWith)) // ['gridLoad']
      const filteredLoad = Object.assign({}, ...filteredKeys.map(key => ({ [key]:this.power[key] })))
      if (this.debug) console.log(filteredLoad)
      return filteredLoad
      /*
      const raw = {
        item1: { key: 'sdfd', value: 'sdfd' },
        item2: { key: 'sdfd', value: 'sdfd' },
        item3: { key: 'sdfd', value: 'sdfd' }
      }
      const filteredKeys = ['item1', 'item3']
      const filtered = Object.assign({}, ...filteredKeys.map(key=> ({[key]:raw[key]})))
      */
    },
    getMeterEnergy() {
      console.log(vedaService.getMeterEnergy())
    },
    async getMeterDetails(meter) {
      const response = await vedaService.getMeterDetails(meter)
      this.meterData[meter] = this.meterData[meter] || {}
      this.meterData[meter].details = JSON.stringify(response.data.Item, null, 2)
      const sourceResponse = await vedaService.getMeterSourceMsg(meter, 0, 10)
      if (this.debug) console.log(sourceResponse.data)
      this.meterData[meter].sourceMsg = sourceResponse.data.energyData.sourceMsgs[0]
    },
    async toggleMeterDetails(meter) {
      if(this.meterData[meter]) {
        delete(this.meterData[meter])
      } else {
        this.getMeterDetails(meter)
      }
    },
    async getSourceMsg() {
      const sourceResponse = await vedaService.getMeterSourceMsg(0, 0, 120)
      if (this.debug) console.log('dashboard:getSourceMsg:sourceResponse', sourceResponse.data)
    },
    async remoteTest() {
      const response = await vedaService.remoteTestDevice()
      if (this.debug) console.log(response)
    },
    downloadEnergyData() {
      if (vedaService.getDebug()) console.log(this.energyData)
      const dataRows: Array<any> = ['timestamp,' + this.meterIds.join(',')]
      for (const i in this.getRange(96, '')) {
        const t = new Date(parseInt(i) * 15 * 60 * 1000).toISOString().substr(11, 5)
        let row: any = this.localDates[0] + ' ' + t
        for (const id in meterIds) {
          row += ',' + (this.energyData[id][this.returnData][i] || '')
        }
        dataRows.push(row)
      }
      const csvData = dataRows.join('\n')
      if (vedaService.getDebug()) console.log(csvData)
      this.download('meterEnergy_' + this.meterIds.join('_') + '_' + this.localDates[0] + '.csv', csvData)
    },
    getRange(intDataPoints, txt) {
      txt = (typeof txt === 'undefined') ? 'e' : txt
      let range = (txt + ',').repeat(intDataPoints).split(',')
      range = range.map((e, i) => e + (1 + i))
      range.length-- // remove last item
      return range
    },
    download(filename, text) {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', filename)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }
})
