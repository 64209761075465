<script>
import { defineComponent } from 'vue'
import { Line } from 'vue3-chart-v2'
import { vedaService } from '../../services/vedaService'
import { userService } from '../../services/userService'

export default defineComponent({
  name: 'PowerChart',
  extends: Line,
  props: {
    meter: { type: String, required: true }
  },
  mounted() {
    setInterval(async () => {
      this.getVIFData()
    }, 5000)
    this.updateChart()
  },
  watch: {
    meter(newData, oldData) {
      this.updateChart()
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        // maintainAspectRatio: false,
        // responsiveAnimationDuration: 0,
        tooltips: { enabled: true, mode: 'index', intersect: false },
        animation: { duration: 0 },
        plugins: {
          legend: { position: 'top' },
          title: { display: true, text: 'VIF Chart' }
        },
        interaction: { intersect: false },
        scales: {
          x: { display: true, title: { display: true } },
          y: {
            display: true,
            title: { display: true, text: 'Value' },
            suggestedMin: 50,
            suggestedMax: 250
          },
          yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
            ticks: { suggestedMax: 260, suggestedMin: 240 }
          }, {
            id: 'B',
            type: 'linear',
            position: 'right',
            ticks: { suggestedMax: 0.2, suggestedMin: 0 }
          }]
        }
      },
      dataMap: {},
      energyData: undefined,
      dataPointsMax: 60,
      debug: false
    }
  },
  methods: {
    async getVIFData() {
      this.debug = vedaService.getDebug()
      if (this.debug) console.log('getVIFData:this.meter: ', this.meter)
      const response = await vedaService.getMeterSourceMsg([this.meter], 0, 120)
      if (this.debug) console.log('getVIFData:response: ', response)
      this.energyData = response.data.energyData.sourceMsgs
      await this.handleNewData(this.energyData)
    },
    async handleNewData(data) {
      if(Array.isArray(data)) {
        data.forEach(item => {
          this.dataMap[item.meterId] = this.dataMap[item.meterId] || {}
          this.dataMap[item.meterId][item.start] = item
        })
        await this.updateChart()
      }
    },
    async updateChart() {
      if (this.meter) {
        const labels = Object.keys(this.dataMap[this.meter]).sort()
        // if (this.debug) console.log('Finding labels, ', labels)
        const voltage = []
        const current = []
        const frequency = []
        labels.forEach(label => {
          const item = this.dataMap[this.meter][label]
          voltage.push(item.Vrms)
          current.push(item.Irms)
          frequency.push(item.f)
        })
        // if (this.debug) console.log('voltage = ', voltage)
        // if (this.debug) console.log('current = ', current)
        // if (this.debug) console.log('frequency = ', frequency)
        const data = {}
        const blankLabels = this.arrayOfBlank(this.dataPointsMax, '')
        data.labels = [...blankLabels, ...labels].slice(-this.dataPointsMax)
        // console.log('datasets ', this.data.datasets)
        data.datasets = [{
            label: 'Voltage',
            backgroundColor: 'transparent',
            yAxisID: 'A',
            borderColor: '#9E70DA',
            data: []
          }, {
            label: 'Current',
            borderColor: '#F0543D',
            backgroundColor: 'transparent',
            yAxisID: 'B',
            data: []
          }, {
            label: 'Frequency',
            borderColor: '#84FA88',
            backgroundColor: 'transparent',
            data: []
          }
        ]
        const blankData = this.arrayOfBlank(this.dataPointsMax, null)
        data.datasets[0].data = [...blankData, ...voltage].slice(-this.dataPointsMax)
        data.datasets[1].data = [...blankData, ...current].slice(-this.dataPointsMax)
        data.datasets[2].data = [...blankData, ...frequency].slice(-this.dataPointsMax)
        if (this.debug) console.log('rendering data ', data)
        if (this.debug) console.log('render options ', this.options)
        if (this.state.chartObj) this.state.chartObj.destroy()
        this.renderChart(data, this.options)
      }
    },
    arrayOfBlank(intDataPoints, value) { // create array of null with specified size
      const arrayOfBlank = []
      for (let i = 0; i <= intDataPoints; i++) {
        arrayOfBlank.push(value)
      }
      return arrayOfBlank
    }
  }
})
</script>
