import { getUniqueArray } from './veb.js'

const modelSchema = [
  {
    modelSchemaId: 'in-2021-1',
    model: '1p-veda3',
    rating: 30,
    energyFlowSvg: { filename: 'EnergyFlow2Circuits.svg', url: '' },
    terminal:{
      1: { l: 'Ain', c: 1, mc: 1.1, r: 1 },
      2: { l: 'Nin', c: 2, mc: 1.2, r: 1 },
      3: { l: 'Nout', c: 2, sc: 2.1 },
      4: { l: 'Aout1', c: 1, sc: 1.1 },
      5: { l: 'Aout2', c: 1, sc: 1.2, mc: 2.1, r: 2 }
    }
  },
  {
    modelSchemaId: 'au-2021-1',
    model: '1p-veda3',
    rating: 30,
    energyFlowSvg: { filename: 'EnergyFlow3Circuits.svg', url: '' },
    terminal:{
      1: { l: 'Ain', c: 1, mc: 1.1, r: 1 },
      2: { l: 'Nin', c: 2 },
      3: { l: 'Nout', c: 2, sc: 2.1 },
      4: { l: 'Aout1', c: 1, sc: 1.1 },
      5: { l: 'Aout2', c: 1, sc: 1.2, mc: 2.1, r: 2 },
      6: { l: 'Aout3', c: 1, sc: 1.3, mc: 1.2, r: 3 }
    }
  },
  {
    modelSchemaId: 'au-2021-2',
    model: 'vemo-1',
    rating: 10,
    input: { current: 10, voltage: [220, 240], frequency: 50 },
    energyFlowSvg: { filename: '', url: '' },
    terminal:{
      1: { l: 'L' },
      2: { l: 'N' },
      3: { l: '&#x23da;' }
    }
  }
]

const getModelSchemaIds = () => {
  return modelSchema.map(ms => ms.modelSchemaId)
}

const getModels = () => {
  return modelSchema.map(ms => ms.modelSchemaId)
}

const getKeyValue = (modelSchemaId, keyName) => {
  const ms = modelSchema.filter(ms => ms.modelSchemaId === modelSchemaId)
  if (ms.length === 0) return null
  return ms[0][keyName] || null
}

const getModel = (modelSchemaId) => {
  return getKeyValue(modelSchemaId, 'model')
}

const getRating = (modelSchemaId) => {
  return getKeyValue(modelSchemaId, 'rating')
}

const getInput = (modelSchemaId) => {
  return getKeyValue(modelSchemaId, 'input')
}

const getEnergyFlowSvg = (modelSchemaId) => {
  return getKeyValue(modelSchemaId, 'energyFlowSvg')
}

const getNumberOfTerminals = (modelSchemaId) => {
  const terminal = getKeyValue(modelSchemaId, 'terminal')
  if (!terminal) return null
  return Object.values(terminal).length || null
}

const getTerminalLabels = (modelSchemaId) => {
  const terminal = getKeyValue(modelSchemaId, 'terminal')
  if (!terminal) return null
  return Object.values(terminal).map(t => t.l) || null
}

const getTerminalCircuits = (modelSchemaId) => {
  const terminal = getKeyValue(modelSchemaId, 'terminal')
  if (!terminal) return null
  return Object.values(terminal).map(t => t.c || null) || null
}

const getTerminalRelays = (modelSchemaId) => {
  const terminal = getKeyValue(modelSchemaId, 'terminal')
  if (!terminal) return null
  return Object.values(terminal).map(t => t.r || null) || null
}

const getNumberOfRelays = (modelSchemaId) => { // todo: effictive or active relays?
  const tr = getTerminalRelays(modelSchemaId)
  if (!tr) return null
  return getUniqueArray(tr).filter(tr => tr).length || null
}

export default {
  modelSchema: modelSchema,
  getModelSchemaIds: getModelSchemaIds,
  getModels: getModels,
  getModel: getModel,
  getRating: getRating,
  getInput: getInput,
  getEnergyFlowSvg: getEnergyFlowSvg,
  getNumberOfTerminals: getNumberOfTerminals,
  getTerminalLabels: getTerminalLabels,
  getTerminalCircuits: getTerminalCircuits,
  getTerminalRelays: getTerminalRelays,
  getNumberOfRelays: getNumberOfRelays
}
