<template>
  <div class="meter-energy-flow">
    <!-- <object id="e-flow" ref="eflow" data="img/EnergyFlow.svg" type="image/svg+xml"></object> -->
    <div :class="showSvg2Circuits ? '' : 'ion-hide'" style="border: solid red 0px;">
      <object id="EnergyFlow2Circuits.svg" data="img/EnergyFlow2Circuits.svg" type="image/svg+xml"></object>
    </div>
    <div :class="showSvg3Circuits ? '' : 'ion-hide'" style="border: solid blue 0px;">
      <object id="EnergyFlow3Circuits.svg" data="img/EnergyFlow3Circuits.svg" type="image/svg+xml"></object>
    </div>
  </div>
</template>

<script>
  import { hasProp, isNumeric, padTo, roundToTwo, sleep, addToLog } from "@/services/util"
  import { vedaService } from '@/services/vedaService'
  import VeModelSchema from '@/services/vemodelschema.js'

  export default {
    name: 'MeterEnergyFlow',
    components: {},
    async mounted() {
      const vv = 'meterenergyflow'
      addToLog('mounted component ' + vv, this.debug)
      const vedaDetails = vedaService.getVedaDetails()
      const meterIds = vedaDetails.meters.map(meter => meter.meterId)
      this.selectedMeterId = meterIds[0]
      addToLog('mounted:selectedMeterId: ' + this.selectedMeterId, this.debug)
      addToLog('energy flow data' + JSON.stringify(this.energyData), this.debug)
      this.getMeterEnergyFlow()
      this.intervalId = setInterval(() => {
        this.getMeterEnergyFlow()
      }, 15000) // Refresh every 15 seconds
    },
    data() {
      return {
        debug: false,
        selectedMeterId: null,
        hmaCount: 0,
        conStatus: {},
        hasSubLoad2: false,
        showSvg2Circuits: true,
        showSvg3Circuits: false,
        intervalId: null,
        svgFileName: 'EnergyFlow2Circuits.svg', // default
        svgCapabilitiesSetup: false,
        energyData: null,
        energyDataDummy: {
        //     data: {
        //         // eslint-disable-next-line @typescript-eslint/camelcase
        //         // test_energy: {
        //         //     data: {
        //         //         capabilities: {
        //         //             grid: true,
        //         //             mainLoad: true,
        //         //             subLoad1MeterId: 'kKwarA37',
        //         //             subLoad1: true,
        //         //             subLoad2MeterId: 'sM2ken23',
        //         //             subLoad2HmaCircuits: [{ name: 'DR_001', status: 'on' }],
        //         //             subLoad2: true
        //         //         },
        //         //         status: {
        //         //             homeToGrid: false,
        //         //             gridToHome: true,
        //         //             homeToMainLoad: true,
        //         //             mainLoadToHome: false,
        //         //             subLoad1ToHome: false,
        //         //             homeToSubLoad1: false,
        //         //             subLoad2ToHome: false,
        //         //             homeToSubLoad2: true
        //         //         },
        //         //         power: { gridLoad: 0.852, subLoad2: 0.083, mainLoad: 0.769 }
        //         //     }
        //         // }
        //     }
        },
      }
    },
    beforeUnmount() {
      clearInterval(this.intervalId)
    },
    methods: {
      async getMeterEnergyFlow() {
        this.debug = vedaService.getDebug() // show / hide debug info
        const response = await vedaService.getMeterEnergyFlow()
        this.energyData = response.data.energyData.data
        addToLog('getMeterEnergyFlow:this.energyData: ' + JSON.stringify(this.energyData), this.debug)
        await this.updateMeterEnergyFlow(this.energyData)
      },
      svgLoader() { // show and hide relevant svg
        addToLog('svgLoader:svgFileName: ' + this.svgFileName, this.debug)
        this.showSvg2Circuits = this.svgFileName.includes('2Circuits')
        this.showSvg3Circuits = this.svgFileName.includes('3Circuits')
      },
      setStyle(strHtmlElement, strStyleElement, strStyleValue) {
        const elm = document.getElementById(strHtmlElement)
        if (elm) elm.style[strStyleElement] = strStyleValue
      },
      setSelectedMeterId(customEvent) { // not used
        if (customEvent && customEvent.detail) {
          if (this.selectedMeterId && this.selectedMeterId !== customEvent.detail) this.stopConnectionAnimation()
          this.selectedMeterId = customEvent.detail
          this.conStatus = { } // reset connection status var
          this.setConfigFromVeModelSchema()
        }
      },
      setConfigFromVeModelSchema() {
        const modelSchemaId = vedaService.getVedaModelSchemaId()
        addToLog('setConfigFromVeModelSchema:modelSchemaId: ' + modelSchemaId, this.debug)
        if (!modelSchemaId) return
        const vedaRelayCount = VeModelSchema.getNumberOfRelays(modelSchemaId)
        this.svgFileName = VeModelSchema.getEnergyFlowSvg(modelSchemaId).filename
        addToLog('setConfigFromVeModelSchema:vedaRelayCount: ' + vedaRelayCount + ', svgFileName: ' + this.svgFileName, this.debug)
        this.svgLoader()
        this.hasSubLoad2 = (vedaRelayCount === 3) // todo: remove
        addToLog('setConfigFromVeModelSchema:this.hasSubLoad2: ' + this.hasSubLoad2, this.debug)
      },
      async updateMeterEnergyFlow(energyData) {
        if (energyData) {
          this.setConfigFromVeModelSchema()
          await sleep(300)
          this.setStyle('mPropTabEnergyFlow', 'display', 'block')
          const svgObject = document.getElementById(this.svgFileName).contentDocument // todo: make global var
          await sleep(100)
          if (svgObject) this.setupLoadForAnimation(energyData, svgObject)
        } else {
          addToLog('updateMeterEnergyFlow:energyData-is-null', true)
        }
      },
      setupLoadForAnimation(energyData, svgObject) {
        if (!this.svgCapabilitiesSetup) this.setCapabilities(svgObject, energyData.capabilities)
        const loadNames = ['gridLoad', 'mainLoad', 'subLoad1'] //, 'subLoad2']
        if (energyData.capabilities.subLoad2) loadNames.push('subLoad2')
        loadNames.map(loadName => {
          const power = energyData.power[loadName]
          this.updateText(svgObject, loadName + 'Power', power)
          this.animateConnection(svgObject, loadName + 'Connection', power)
        })
      },
      stopConnectionAnimation() {
        const svgObject = document.getElementById(this.svgFileName).contentDocument // todo: make global var
        if (!svgObject) return
        const loadNames = ['gridLoad', 'mainLoad', 'subLoad1', 'subLoad2']
        loadNames.map(loadName => {
          this.updateText(svgObject, loadName + 'Power', undefined)
          this.animateConnection(svgObject, loadName + 'Connection', undefined)
        })
        // hide hma
        let hmaCount = 0
        while (hmaCount < this.hmaCount) {
          hmaCount++
          const thisHma = svgObject.querySelector('#hmaSvg' + hmaCount.toString())
          const thisHmaConnection = svgObject.querySelector('#hmaConnection' + hmaCount.toString())
          this.setSvgAttributes(thisHma, [['display', 'none']])
          this.setSvgAttributes(thisHmaConnection, [['x1', 0], ['y1', 0], ['x2', 10], ['y2', 10], ['display', 'none']])
        }
      },
      updateText(svgObject, id, value) {
        let txtValue = '(...kW)' // default value
        addToLog('updateText:id: ' + id + ', value: ' + value + ', txtValue: ' + txtValue, this.debug)
        if (value) txtValue = txtValue.replace('...', padTo(value, 3))
        const elm = svgObject.querySelector('#'+ id)
        if (elm) elm.innerHTML = txtValue
      },
      setOfferAndQuestionDisplay(svg, loadName, displayValue) { // not used atm
        const offerArrow = svg.querySelector('#' + loadName + 'OfferArrow')
        const offer = svg.querySelector('#' + loadName + 'Offer')
        const question = svg.querySelector('#' + loadName + 'Question')
        if (offerArrow?.style) offerArrow.style.display = displayValue
        if (offer?.style) offer.style.display = displayValue
        if (question?.style) question.style.display = displayValue
      },
      setConnectionAndTextGroupDisplay(svg, loadName, displayValue) { // not used atm
        const connection = svg.querySelector('#' + loadName + 'Connection')
        const textGroup = svg.querySelector('#' + loadName + 'TextGroup')
        if (connection?.style) connection.style.display = displayValue
        if (textGroup?.style) textGroup.style.display = displayValue
      },
      setCapabilities(svgObject, capabilities) {
        // "subLoad2HmaCircuits":[{"name":"DR_001","status":"on"}],
        this.hmaCount = 0
        if (hasProp(capabilities, 'subLoad2HmaCircuits')) {
          for (const hmaCircuit of capabilities.subLoad2HmaCircuits) {
            this.hmaCount++
            const thisHma = svgObject.querySelector('#hmaSvg' + this.hmaCount.toString())
            const thisHmaConnection = svgObject.querySelector('#hmaConnection' + this.hmaCount.toString())
            this.setSvgAttributes(thisHma, [['x', 140], ['y', 290], ['display', '']])
            this.setSvgAttributes(thisHmaConnection, [['x1', 160], ['y1', 250], ['x2', 165], ['y2', 290], ['display', '']])
          }
        }
        this.svgCapabilitiesSetup = true
      },
      setSvgAttributes(svg, attributes) {
        if (!svg) return
        for (const att of attributes) svg.setAttribute(att[0], att[1])
      },
      animateConnection(svgObject, id, power) {
        const loadConnection = svgObject.querySelector('#' + id)
        if (!this.conStatus[id]) this.conStatus[id] = { } // init
        if (isNumeric(power)) {
          const dash = 5
          const offset = -10
          const strokeWidth = 4 + roundToTwo(Math.abs(power)) + 'px'
          loadConnection.style.strokeWidth = strokeWidth
          loadConnection.style.strokeDasharray = dash + ',' + dash
          const previousStroke = this.conStatus[id].stroke || null
          const newStroke = (power > 0) ? 'blue' : 'green'
          if (previousStroke === newStroke) return // all done, already animated
          loadConnection.style.stroke = newStroke
          this.conStatus[id].stroke = newStroke
          loadConnection.style.animationDirection = (power > 0) ? 'normal' : 'reverse'
          // Add a 100% keyframe that offsets by one full dash group
          const emptyArray = []
          emptyArray.slice.call(svgObject.styleSheets).map(stylesheet => {
            emptyArray.slice.call(stylesheet.cssRules
            ).map(rule => {
              if (rule.name === 'move') {
                rule.appendRule('100% { stroke-dashoffset: ' + offset + '; }')
              }
            })
          })
        } else {
          const defaultStroke = 'darkgray' // '#29204E'
          this.conStatus[id].stroke = defaultStroke
          loadConnection.style.stroke = defaultStroke
          loadConnection.style.strokeWidth = '4px'
          loadConnection.style.strokeDasharray = 0
        }
      }
    }
  }
</script>
<style>
  .meter-energy-flow { text-align: center; }
</style>
