<script>
import { defineComponent } from 'vue'
import { Bar } from 'vue3-chart-v2'
export default defineComponent({
  name: "MonthlyChart",
  extends: Bar,
  props: {
    meterIds: {
      type: Array,
      required: true
    },
    energyData: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // let chartData = {}
    const datasets = []
    // const labels = Array(24)
    let numberOfLabels = 0
    console.log(this.energyData)
    const energyData = Object.assign([], this.energyData)
    const meterIds = Object.assign([], this.meterIds)
    // console.log(energyData)

    for (let i = 0; i < energyData.length; i++) { // For each meter
      const dataObj = Object.assign({}, energyData[i])
      // console.log(dataObj)
      const aligned15minRead = Object.assign([], dataObj.aligned15minRead)
      const data = {
        label: meterIds[i],
        backgroundColor: i == 0? '#0671fa': '#fdbe57',
        data: aligned15minRead
      }
      if (numberOfLabels < aligned15minRead.length) {
        numberOfLabels = aligned15minRead.length
      }
      datasets.push(data)
      // console.log(aligned15minRead);
    }
    const labels = Array(numberOfLabels)
    let timeValue = -1
    for (let i = 0; i < labels.length; i++) {
      if (i % 4 === 0) timeValue++
      labels[i] = (i % 4 === 0) ? timeValue : ''
    }
    // console.log(labels)
    // console.log(datasets)
    const chartData = {
      labels,
      datasets
    }
    this.renderChart(chartData)
    // Overwriting base render method with actual data.
    // SAMPLE DATA
    // this.renderChart({
    //   labels: [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ],
    //   datasets: [
    //     {
    //       label: "GitHub Commits",
    //       backgroundColor: "#f87979",
    //       data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
    //     },
    //     {
    //       label: "Something",
    //       backgroundColor: "#00ffff",
    //       data: [10, 30, 10, 19, 50, 40, 39, 60, 20, 10, 2, 10]
    //     }
    //   ],
    // });
    // Energy DATA
    // {
    //   labels: [minutes],
    //   datasets: [
    //     {
    //       label: 'meter 1',
    //       backgroundColor: '',
    //       data: [meter 1 readings]
    //     },
    //     {
    //       label: 'meter 2',
    //       backgroundColor: '',
    //       data: [meter 2 readings]
    //     },
    //   ]
    // }
  }
})
</script>
